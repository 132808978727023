import React from "react";

import Anchor from "./Anchor";
import { linkResolver } from "../../utilities/prismic-linkResolver";
import siteUrl from "../../../config/site-config";

export default (type, element, content, children, index) => {
  if (!element) return null;

  const { data, start, end } = element;
  const { link_type: linkType } = data;

  // Link to an internal prismic document
  if (linkType === "Document") {
    return (
      <Anchor
        href={linkResolver(data)}
        key={data.id}
        className="anchor-prismic anchor-prismic--document"
      >
        {content}
      </Anchor>
    );
  }

  // Link to an internal prismic media file
  if (linkType === "Media") {
    return (
      <a
        href={data.url}
        className="anchor-prismic anchor-prismic--media"
        target="_blank"
        rel="noreferrer"
        key={start + end}
      >
        {content}
      </a>
    );
  }

  // Link to an external url
  if (linkType === "Web") {
    const relvalue = data.target === "_blank" ? "noreferrer" : null;

    return (
      <a
        href={
          data.url.includes(siteUrl) ? data.url.replace(siteUrl, "") : data.url
        }
        className="anchor-prismic anchor-prismic--web"
        target={data.target}
        rel={relvalue}
        key={start + end}
      >
        {content}
      </a>
    );
  }

  return null;
};
